<template>
  <div class="stats text-white pb-20" v-if="stats">
    <Header />
    <div class="flex w-full justify-between pt-4 px-4 items-center">
      <p
        class="font-ZuumeSemiBold text-nColorYellow text-5xl text-shadow-black-right"
      >
        {{ stats.game_name }}
      </p>
    </div>
    <div class="flex gap-4 px-4">
      <p
        class="font-InterSemiBold text-nColorYellow text-sm text-shadow-black-right"
      >
        {{ formattedGamePlays }}.
      </p>
      <p
        class="font-InterSemiBold text-nColorYellow text-sm text-shadow-black-right"
      >
        {{ formattedLeaderboardCount }}.
      </p>
    </div>
    <div v-if="stats.leaderboard" class="w-full px-4 pt-2">
      <div v-for="(result, index) in stats.leaderboard.results" :key="result">
        <div
          class="flex justify-between items-center gap-2 text-white rounded-xl p-2"
        >
          <div class="w-10 text-center">
            <div class="text-nColorGreen font-bold font-InterSemiBold text-xl">
              <p>{{ index + 1 }}</p>
            </div>
          </div>
          <div class="w-3/6">
            <p class="font-InterSemiBold text-lg">{{ result.player }}</p>
            <p class="font-InterRegular text-xs text-nColorSubTextGray">
              {{ result.mobile }}
            </p>
          </div>
          <div class="w-3/6">
            <div
              class="bg-lColorBGGray p-0.5 rounded-xl border-4 border-lColorBGGray"
            >
              <div class="flex gap-2">
                <div>
                  <img
                    src="https://playda.s3.ap-south-1.amazonaws.com/assets/leaderboard/score.webp"
                    alt="Score"
                    width="20"
                    height="19"
                  />
                </div>
                <p class="font-InterSemiBold text-sm">
                  {{ result.highest_score.score }}
                </p>
              </div>
              <div class="flex gap-2 mt-2" v-if="result.highest_score.time">
                <div class="pl-1">
                  <img
                    src="https://playda.s3.ap-south-1.amazonaws.com/assets/leaderboard/time-taken.webp"
                    alt="Time Taken"
                    width="15"
                    height="14"
                  />
                </div>
                <p class="font-InterSemiBold text-sm">
                  {{ timeTaken(result.highest_score.time) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fixed bottom-0 w-full">
      <div class="w-full grid">
        <div
          class="goto-game-button fixed bottom-0 w-screen bg-nColorOrange rounded-t-2xl z-20"
        >
          <button
            :class="[
              'text-nColorDarkGray font-ZuumeSemiBold text-3xl flex w-full justify-center items-center py-2 gap-2 h-14',
              { disabled: isLoading },
            ]"
            @click.prevent="getReportOnMail"
            :disabled="isLoading"
          >
            <span v-if="isLoading">
              <svg
                width="60"
                height="15"
                viewBox="0 0 120 30"
                xmlns="http://www.w3.org/2000/svg"
                fill="#000"
              >
                <circle cx="15" cy="15" r="15">
                  <animate
                    attributeName="r"
                    from="15"
                    to="15"
                    begin="0s"
                    dur="0.8s"
                    values="15;9;15"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                  <animate
                    attributeName="fill-opacity"
                    from="1"
                    to="1"
                    begin="0s"
                    dur="0.8s"
                    values="1;.5;1"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                </circle>
                <circle cx="60" cy="15" r="9" fill-opacity="0.3">
                  <animate
                    attributeName="r"
                    from="9"
                    to="9"
                    begin="0s"
                    dur="0.8s"
                    values="9;15;9"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                  <animate
                    attributeName="fill-opacity"
                    from="0.5"
                    to="0.5"
                    begin="0s"
                    dur="0.8s"
                    values=".5;1;.5"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                </circle>
                <circle cx="105" cy="15" r="15">
                  <animate
                    attributeName="r"
                    from="15"
                    to="15"
                    begin="0s"
                    dur="0.8s"
                    values="15;9;15"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                  <animate
                    attributeName="fill-opacity"
                    from="1"
                    to="1"
                    begin="0s"
                    dur="0.8s"
                    values="1;.5;1"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                </circle>
              </svg>
            </span>
            <span v-else>get report on mail</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Header from "@/components/Elements/Header.vue";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  name: "Stats",
  components: { Header },
  data() {
    return {
      stats: null,
      isLoading: false,
    };
  },
  computed: {
    formattedGamePlays() {
      if (this.stats.total_game_plays >= 1000) {
        return (this.stats.total_game_plays / 1000).toFixed(2) + "k Game Plays";
      } else {
        return this.stats.total_gameplays + " Game Plays";
      }
    },
    formattedLeaderboardCount() {
      if (this.stats.players >= 1000) {
        return (this.stats.players / 1000).toFixed(2) + "k on Leaderboard";
      } else {
        return this.stats.players + " on Leaderboard";
      }
    },
  },
  created() {
    ApiService.get(apiResource.getScheduleDetails, {
      business_slug: this.$route.params.business,
      schedule_id: this.$route.params.schedule_id,
    }).then((data) => {
      this.stats = data.data.data;
    });
  },
  mounted() {},
  methods: {
    timeTaken(seconds) {
      let minutes = Math.floor(seconds / (1000 * 60));
      let extraSeconds = Math.floor((seconds % (1000 * 60)) / 1000);
      let milliseconds = Math.round(seconds % 1000);
      minutes = minutes < 10 ? "0" + minutes : minutes;
      extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
      return minutes + ":" + extraSeconds + ":" + milliseconds;
    },
    getReportOnMail() {
      this.isLoading = true;
      ApiService.get(apiResource.emailScheduleDetails, {
        business_slug: this.$route.params.business,
        schedule_id: this.$route.params.schedule_id,
      })
        .then(() => {
          this.isLoading = false;
          toast.success("Email sent successfully", { timeout: 2000 });
        })
        .catch(() => {
          this.isLoading = false;
          toast.error("Something went wrong. Please try again later", {
            timeout: 2000,
          });
        });
    },
  },
};
</script>

<style scoped>
.stats {
  min-height: 100dvh;
}
</style>
